export * from './categories';
export * from './platen';
export * from './api';
export * from './templates';
export * from './settings';
export * from './artwork';
export * from './usStatesByCode';
export * from './shopify';
export * from './standalone';
export * from './thirdPartyServices';
export * from './misc';

export enum PLATFORM_ENUM {
  FUEL = 'fuel',
  SHOPIFY = 'shopify',
}

export enum SHIPPING_METHOD_ENUM {
  EXPEDITED = 'expedited',
  STANDARD = 'standard',
}

export enum PLAN_NAME_ENUM {
  TRIAL = 'trial',
  BASIC = 'basic',
  AFFILIATE = 'affiliate',
}

export enum FEATURES {
  NECK_LABEL = 'Branded Neck Label',
  CUSTOM_RETURN = 'Custom Return Address',
  COLOR_CHANGING = 'Color Changing',
}

export enum TIERS {
  PROMOTION = 'PromotionTierV0',
  BASE = 'BaseTier',
  BETA = 'BetaTier',
  POWER = 'PowerSellerTier',
  DEV = 'DevTier',
  ADVANCE = 'AdvancedSeller',
}

export enum FILTER_BY {
  ALL = 'ALL',
  QUICKSHIP = 'Quickship',
  NEW = 'New',
  POPULAR = 'Popular',
}

export enum SHOW_SHIPPING_COST {
  DOMESTIC = 'US',
  INTERNATIONAL = 'Worldwide',
  NON = "Don't show Shipping Cost",
}

export enum ORDER_COLLECTION {
  'Headwear & Apparel' = 1,
  'Accessories & Drinkware' = 2,
  'Home & Living' = 3,
}

export enum ORDER_CATEGORY {
  'Embroidered Hats' = 1,
  'Apparel' = 2,
  'Phone Cases' = 3,
  'Mugs' = 4,
  'Bottles & Tumblers' = 5,
  'Seasonal Items' = 6,
  'Beddings' = 7,
  'Home Decor' = 8,
  'Wall Art' = 9,
  'Fitness & Wellness' = 10,
}

export enum ORDER_SUBCATEGORY {
  'Trucker hats' = 1,
  'Dad caps' = 2,
  '5 panel' = 3,
  '6 panel' = 4,
  'Beanies' = 5,
  'Classic T-Shirts' = 6,
  'Premium Fit Mens Tees' = 7,
  'Unisex Tanks' = 8,
  'Premium Fit Ladies Tees' = 9,
  'Ladies Tanks Tops' = 10,
  'Long Sleeve Tees' = 11,
  'Youth T-Shirts' = 12,
  'V-Necks' = 13,
  'Sweatshirts' = 14,
  'Hoodies' = 15,
  'Scarves' = 16,
  'iPhone Cases' = 17,
  'Samsung Cases' = 18,
  'Mugs' = 19,
  'Tumblers' = 20,
  'Ornaments' = 21,
  'Blankets' = 22,
  'Yard Sign' = 23,
  'Flags' = 24,
  'Doormat' = 25,
  'Poster' = 26,
  'Canvas' = 27,
  'Yoga Mats' = 28,
}

export enum ORDER_PRODUCT_TYPE {
  'Matte' = 1,
  'Gloss' = 2,
  'Wrap Canvas' = 3,
  'Hanging Canvas' = 4,
  'Floating Canvas' = 5,
}

export const tiersName: Record<TIERS, string> = {
  [TIERS.PROMOTION]: 'Promotion Tier',
  [TIERS.BASE]: 'Base Tier',
  [TIERS.BETA]: 'Beta Tier',
  [TIERS.POWER]: 'Power Tier',
  [TIERS.DEV]: 'Dev Tier',
  [TIERS.ADVANCE]: 'Advance Tier',
};

// This is the quantity of colors the palette will show in ProductItem -> catalog product card
export const NUMBER_COLORS_SHOW_COLPASE = 10;
export const COLOR_PALETTE_MAX = 12;

export const BUILD_NAME = import.meta.env.VITE_BUILD_NAME ?? 'N/A';

export const CAN_EXPEDITE_SHIPPING_COUNTRIES = ['US'];

export enum contactInfoManagment {
  MANAGE_CONTACT_INFO = 'Manage Contact Info',
  UNLOCK_CONTACT_INFO = 'Unlock Intro Price',
}

export const DEFAULT_MARGIN = '66.67';
